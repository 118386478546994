import { oneOf, string } from "prop-types";

import Button from "~components/Button";

/**
 * LinkButton component
 *
 * @param {object} props - Component props
 * @param {string} props.label - The button label (required)
 * @param {string} props.slug - The URL slug
 * @param {string} [props.params=""] - Additional URL params
 * @param {"solid" | "outline" | "text"} [props.variant="solid"] - Button variant (default: "solid")
 * @param {"black" | "primary" | "secondary" | "white" | string} [props.color="black"] - Button color (default: "black")
 * @param {string} [props.className] - Additional CSS classes
 *
 * @example
 * // Renders a button linking to '/about?ref=nav'
 * <LinkButton label="About" slug="/about" params="?ref=nav" />
 */

const LinkButton = ({
  label,
  slug,
  params,
  variant = "solid",
  color = "black",
  className,
}) => {
  if (!label || !slug) {
    return null;
  }
  const safeParams = params ?? "";
  const href = slug ? `${slug}${safeParams}` : "/";

  return (
    <Button variant={variant} color={color} href={href} className={className}>
      {label}
    </Button>
  );
};

LinkButton.propTypes = {
  label: string.isRequired,
  slug: string.isRequired,
  params: string,
  variant: oneOf(["solid", "outline", "text"]),
  color: oneOf(["black", "primary", "secondary", "white"]),
  className: string,
};

export default LinkButton;
